<template>
    <q-page class="flex flex-center row">
        <q-card class="col-10">
            <q-tabs>
                <q-route-tab label="Utilisateurs" to="/admin/users" exact />
                <q-route-tab label="Paiements" to="/admin/payments" exact />
            </q-tabs>
            <q-separator />
            <q-tab-panel name="admin_views" class="q-pa-none">
                <router-view></router-view>
            </q-tab-panel>
        </q-card>
    </q-page>
</template>
